import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { cn } from "../utils";

interface ScrollerProps {
  children?: React.ReactNode;
  className?: string;
}

export const ScrollArea = ({ className, children }: ScrollerProps) => {
  return (
    <ScrollAreaPrimitive.Root className={cn("relative overflow-hidden", className)}>
      <ScrollAreaPrimitive.Viewport className="h-full w-full rounded-[inherit]">
        {children}
      </ScrollAreaPrimitive.Viewport>

      <ScrollAreaPrimitive.Scrollbar
        className={cn("flex touch-none select-none transition-colors", "h-full w-2.5 border-l-transparent p-0.5")}
        orientation="vertical"
      >
        <ScrollAreaPrimitive.Thumb className="bg-content/30 relative flex-1 rounded-full" />
      </ScrollAreaPrimitive.Scrollbar>

      <ScrollAreaPrimitive.Scrollbar
        className={cn("flex touch-none select-none transition-colors", "h-2.5 w-full border-l-transparent p-0.5")}
        orientation="horizontal"
      >
        <ScrollAreaPrimitive.Thumb className="bg-content/30 relative flex-1 rounded-full" />
      </ScrollAreaPrimitive.Scrollbar>

      <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
  );
};
